<!-- ----------------------------------------------------------------------- -->
<!--
name    : HEADER VIEW

type    : component

used by : almost everywhere

uses    : table-status

route   : none
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <!-- + (dialog ? '0px;color:white;' : '64px;') -->
  <v-app-bar
    class="header-view-component"
    :style="'z-index:2;position:sticky;top:0'"
    :extension-height="$vuetify.breakpoint.xsOnly ? 72 : 36"
    :flat="dialog"
    :color="dialog ? 'info' : ''">
    <a
      v-if="previous_page != 'NO_BACK_BUTTON'"
      @click="previous_page ? $router.push(previous_page) : $router.go(-1)">
      <v-icon class="headline">mdi-chevron-left</v-icon>
    </a>
    <v-toolbar-title v-if="job.number">
      <span class="headline ml-3 mr-2">
        {{
          'JOB-' + job.number.toString().padStart(5, '0') + (title ? ': ' : '')
        }}
      </span>
    </v-toolbar-title>
    <v-toolbar-title>
      <span
        :class="!dialog ? 'headline' : ''"
        :style="dialog ? 'color:white' : ''">
        <!--if short title is provided it will be used in xs screens-->
        {{ $vuetify.breakpoint.xsOnly && short_title ? short_title : title }}
      </span>
    </v-toolbar-title>

    <v-spacer></v-spacer>
    <div v-for="option in buttons" :key="option.name + ' ' + option.icon">
      <v-btn
        @click.stop="option.action"
        v-if="option.show !== false"
        :loading="option.loading"
        class="ma-2"
        :color="
          option.btnColor
            ? option.btnColor
            : option.icon && !option.name
            ? ''
            : 'button-primary'
        "
        :disabled="option.disabled || false"
        :width="option.width || ''"
        :icon="option.name == ''"
        :title="option.title || ''"
        :outlined="dialog && option.name != ''">
        <v-icon
          :class="option.name == '' ? '' : 'mr-1'"
          v-if="option.icon"
          :small="option.name != ''">
          {{ option.icon }}
        </v-icon>
        {{ option.name }}
      </v-btn>
    </div>
    <v-btn
      v-if="edit_url && allow_edit"
      @click="$router.push(edit_url)"
      class="ma-2"
      icon>
      <v-icon>mdi-pencil</v-icon>
    </v-btn>
    <v-btn
      v-if="allow_download"
      @click="openNewTab(download_url)"
      :loading="!download_url"
      :disabled="!download_url"
      class="ma-2 pt-1"
      icon>
      <v-icon>mdi-download</v-icon>
    </v-btn>
    <!-- workorderview-specific implementation in lieu of
    updating all modules that rely on download url (above) to
    switch to this method -->
    <v-btn
      v-if="allow_workorder_download"
      @click="generateDownload()"
      :loading="loading_workorder_url"
      class="ma-2 pt-1"
      icon>
      <v-icon>mdi-download</v-icon>
    </v-btn>
    <v-btn
      v-if="closeBtn"
      @click="callCloseAction"
      class="ma-2 mr-0"
      text
      icon
      :color="dialog ? 'white' : ''">
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <div slot="extension" v-if="status || job_phase" :style="'width:100%;'">
      <v-row>
        <v-divider></v-divider>
      </v-row>
      <v-row dense no-gutters align="center" justify="space-between">
        <v-col cols="12" sm="6" lg="3" align-self="center" class="d-flex ma-0">
          <v-btn style="pointer-events: none" text>{{ status_label }} </v-btn>

          <table-status
            :height="28"
            class="my-1"
            v-bind:displayedStatus="displayedStatus"
            v-bind:status="status" />
        </v-col>
        <v-col
          v-for="n in 7"
          :id="n"
          :key="n"
          align-self="center"
          class="d-flex ml-5 mt-1 mb-2"
          style="align-items: center">
          <v-btn
            v-if="n === 1 || n === 3"
            :id="'job_phases_' + (n - 1) / 2"
            small
            @click="job_phases[(n - 1) / 2].click()"
            :disabled="!job_phases[(n - 1) / 2].editable"
            :color="job_phases[(n - 1) / 2].color"
            :style="
              job_phases[(n - 1) / 2].color == 'in-progress'
                ? 'color:white;'
                : ''
            ">
            <v-icon>{{ job_phases[(n - 1) / 2].icon }}</v-icon>
          </v-btn>
          <v-menu offset-y v-else-if="n === 5 || n === 7">
            <template v-slot:activator="{on, attrs}">
              <v-btn
                small
                :id="'job_phases_' + (n - 1) / 2"
                v-bind="attrs"
                :disabled="!job_phases[(n - 1) / 2].editable"
                :color="job_phases[(n - 1) / 2].color"
                :style="
                  job_phases[(n - 1) / 2].color == 'in-progress'
                    ? 'color:white;'
                    : ''
                "
                v-on="on">
                <v-icon>{{ job_phases[(n - 1) / 2].icon }}</v-icon>
              </v-btn>
            </template>
            <!-- <template v-slot:activator="{ on }">
            <v-btn small
                   :id="'job_phases_'+((n-1)/2)"
                   :min-width="$vuetify.breakpoint.mdAndDown ? 75 : 190"
                   :disabled="!job_phases[(n-1)/2].editable"
                   :color="job_phases[(n-1)/2].color"
                   :style="job_phases[(n-1)/2].color == 'in-progress' ? 'color:white;' : ''"
                   v-on="on">
              hello{{ job_phases[(n-1)/2].name }}
            </v-btn>
          </template> -->
            <v-list>
              <v-list-item
                v-for="(item, index) in job_phases[(n - 1) / 2].items"
                :key="index"
                @click="job_phases[(n - 1) / 2].click(item)">
                <v-list-item-title>{{
                  job_phases[(n - 1) / 2].name.toLowerCase().includes('inv')
                    ? 'INV-' + item.number.toString().padStart(5, '0')
                    : item.name
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-divider v-else></v-divider>
        </v-col>
      </v-row>
    </div>
  </v-app-bar>
</template>

<script>
  import TableStatus from '@/components/table-status';
  import Jobs from '@/services/Jobs.service.js';
  import Invoices from '@/services/Invoices.service.js';

  export default {
    name: 'header-view',
    components: {
      'table-status': TableStatus,
    },
    props: {
      title: {
        default: 'Header',
        type: String,
      },
      status_title: {
        default: 'Status',
        type: String,
      },
      short_title: {
        default: '',
        type: String,
      },
      btnOptions: {
        default: () => [],
        type: Array,
      },
      previous_page: {
        default: 'NO_BACK_BUTTON',
        type: String,
      },
      edit_url: {
        default: '',
        type: String,
      },
      allow_edit: {
        default: true,
        type: Boolean,
      },
      allow_download: {
        default: false,
        type: Boolean,
      },
      download_url: {
        default: '',
        type: String,
      },
      // workorderview specific url
      // link in lieu of updating the other
      // download url containing views to
      // match this method
      allow_workorder_download: {
        default: false,
        type: Boolean,
      },
      loading_workorder_url: {
        default: false,
        type: Boolean,
      },
      id: {
        type: String,
        default: '',
      },
      closeBtn: {
        type: Boolean,
        default: false,
      },
      closeAction: {
        type: Function,
      },
      deleteAction: {
        type: Function,
      },
      status: {
        type: String,
        default: '',
      },
      displayedStatus: {
        type: String,
        default: '',
      },
      job_uuid: {
        type: String,
        default: '',
      },
      view_phase: {
        type: String,
        default: '',
      },
      dialog: {
        type: Boolean,
        default: false,
      },
      workorders: {
        type: Array,
        default: null,
      },
    },
    data() {
      return {
        job: {
          uuid: '',
          number: '',
          tenant_uuid: '',
          client_uuid: '',
          phase: '',
        },
        sr: {},
        est: {
          uuid: '',
        },
        wo: [],
        inv: [],
        job_phase: 0,
      };
    },
    computed: {
      buttons() {
        // console.log(this.btnOptions);
        return this.btnOptions;
      },
      job_phases() {
        const b = this.$vuetify.breakpoint.mdAndDown;
        // console.log( b )
        return [
          {
            name: b ? 'SR' : 'Service Request',
            complete: this.job_phase > 0,
            editable: this.$auth.isAllowed('servicerequests', 'view'),
            icon: 'mdi-leaf',
            click: () => {
              // console.log( this.sr );
              this.$router.push({
                name: 'ServiceRequestView',
                params: {
                  uuid: this.sr.uuid,
                },
              });
            },
            color: this.view_phase == 'Service Request' ? 'in-progress' : '',
          },
          {
            name: b ? 'EST' : 'Estimate',
            complete: this.job_phase > 1,
            editable:
              this.job_phase > 0 && this.$auth.isAllowed('estimates', 'view'),
            icon: 'mdi-format-list-numbered',
            click: () => {
              this.$router.push({
                name: 'EstimateView',
                params: {
                  uuid: this.est.uuid,
                },
              });
            },
            color: this.view_phase == 'Estimate' ? 'in-progress' : '',
          },
          {
            name: b ? 'WO' : 'Work Order',
            complete: this.job_phase > 2,
            editable:
              this.job_phase > 1 && this.$auth.isAllowed('workorders', 'view'),
            icon: 'mdi-home-city-outline',
            items: this.wo,
            click: (item) => {
              // IMPORTANT: for some reason $router.push won't work in this
              // case when the app was already on an existing workorder view,
              // it will only change the url. We need to do a replace on the url
              // and then a blank push to do the routing. Will research and change
              // if time permits

              this.$router
                .push({
                  name: 'WorkOrderView',
                  params: {
                    uuid: item.uuid,
                  },
                })
                .then(() => {
                  // because of the reason described above, if we are already
                  // on the workorder view page, the router.push only updates the
                  // url, so we must do a router.go to actually refresh the page
                  if (this.$route.path.includes('workorders/view')) {
                    this.$router.go();
                  }
                })
                .catch((err) => {
                  console.log('Error: ', err);
                });
            },
            color: this.view_phase == 'Work' ? 'in-progress' : '',
          },
          {
            name: b ? 'INV' : 'Invoice',
            complete: this.job_phase > 3,
            editable:
              this.job_phase > 2 && this.$auth.isAllowed('invoices', 'view'),
            icon: 'mdi-tag-text-outline',
            items: this.inv,
            click: (item) => {
              // IMPORTANT: for some reason $router.push won't work in this
              // case when the app was already on an existing invoice view,
              // it will only change the url. We need to do a replace on the url
              // and then a blank push to do the routing. Will research and change
              // if time permits
              this.$router
                .push({
                  name: 'InvoiceView',
                  params: {
                    uuid: item.uuid,
                  },
                })
                .then(() => {
                  // because of the reason described above, if we are already
                  // on the workorder view page, the router.push only updates the
                  // url, so we must do a router.go to actually refresh the page
                  if (this.$route.path.includes('invoices/view')) {
                    this.$router.go();
                  }
                })
                .catch((err) => {
                  console.log('Error: ', err);
                });
            },
            color: this.view_phase == 'Invoice' ? 'in-progress' : '',
          },
        ];
      },
      status_label: function () {
        if (this.status_title == 'Status' && this.id) {
          return this.id;
        } else {
          return this.status_title;
        }
      },
    },
    watch: {
      job_uuid: function (val) {
        this.loadJobPhases(val);
      },
      status: function () {
        this.loadJobPhases(this.job_uuid);
      },
    },
    mounted() {
      if (this.workorders) {
        this.wo = this.workorders;
      }

      // event that lets header view know workorders changed (ie workorder was added or removed)
      this.$events.$on('workOrdersChanged', this.onWorkOrdersChange);

      // event that lets header view know job phase changed
      this.$events.$on('jobPhaseChanged', this.onJobPhaseChange);

      if (this.job_uuid) {
        this.loadJobPhases();
      }
    },
    methods: {
      async loadJobPhases() {
        // console.log( "HEADER VIEW: (loadJobPhases) : ", job_uuid )
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        // get job
        const job = await Jobs.getJob(this.job_uuid, accessToken);

        if (job) {
          // parse job phase
          switch (job.phase) {
            case 'Service Request':
              this.job_phase = 0;
              break;
            case 'Estimate':
              this.job_phase = 1;
              break;
            case 'Work':
              this.job_phase = 2;
              break;
            case 'Invoice':
              this.job_phase = 3;
              break;
            default:
              this.job_phase = 0;
              break;
          }

          // get service request
          const service_request = await Jobs.getServiceRequestByJobUUID(
            this.job_uuid,
            accessToken
          );

          if (service_request) {
            this.sr = service_request;
          }

          // get estimate
          const estimate = await Jobs.getEstimateByJobUUID(
            this.job_uuid,
            accessToken
          );

          if (estimate) {
            this.est = estimate;
          }

          // get invoices
          const invoices = await Invoices.getInvoicesByTenant(
            job.tenant_uuid,
            {
              job_uuid: this.job_uuid,
            },
            accessToken
          );

          if (invoices) {
            this.inv = invoices;
          }

          this.job = job;
        }

        // get work order list if one wasn't passed in
        if (!this.wo || !(this.wo.length > 0)) {
          // get all work orders for job
          this.wo = await Jobs.getAllWorkOrders(
            {
              job_uuid: this.job_uuid,
            },
            accessToken
          );
        }
        // await this.sr
        // await this.est
        // await this.inv
        // await this.wo
        // console.log( "SR :", this.sr )
        // console.log( "EST :", this.est )
        // console.log( "INV :", this.inv )
        // console.log( "WO :", this.wo )
      },
      callCloseAction: function () {
        // executes close action function if one was sent in
        // (would apply to dialogs),
        // otherwise routes to previous page
        if (this.closeAction) {
          this.closeAction();
        } else {
          this.previous_page && this.previous_page != 'NO_BACK_BUTTON'
            ? this.$router.push(this.previous_page)
            : this.$router.go(-1);
        }
      },

      openNewTab: function (url) {
        window.open(url, '_blank');
      },
      // workorderview-specific implementation in lieu of
      // updating all modules that rely on the download url function
      // (above) to switch to this method
      generateDownload: function () {
        //  console.log("generateDownload")
        // request download link to be generated by parent component
        this.$emit('generate_download_link');
      },
      onDownloadLinkReady: function (url) {
        // console.log("onDownloadLink", url)
        window.open(url, '_blank');
      },
      onWorkOrdersChange: function (newWorkorders) {
        // console.log( "workorders changed!", newWorkorders )
        // console.log( this.job_phase )
        if (newWorkorders) {
          this.wo = newWorkorders;
        }
      },
      onJobPhaseChange: function () {
        // console.log('onJobPhaseChange');
        if (this.job_uuid) {
          this.loadJobPhases(this.job_uuid);
        }
      },
    },
  };
</script>
<style>
  .v-toolbar__content {
    padding-right: 8px !important;
  }
</style>
