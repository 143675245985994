// src/services/Invoices.service.js

import axios from 'axios';
import {uuid} from 'vue-uuid';

export default {
  blankInvoice() {
    return {
      uuid: uuid.v4(),
      tenant_uuid: '',
      job_uuid: '',
      client_uuid: '',
      number: 0, //this is only set server side to ensure uniqueness
    };
  },
  blankInvoiceItem() {
    return {
      uuid: uuid.v4(),
      tenant_uuid: '',
      job_uuid: '',
      client_uuid: '',
      description: '',
      amount: 0,
    };
  },

  blankInvoicePayment() {
    return {
      uuid: uuid.v4(),
      tenant_uuid: '',
      job_uuid: '',
      client_uuid: '',
      description: '',
      amount: 0,
    };
  },

  created() {
    // handle api call errors
    axios.interceptors.response.use(
      // response => response,
      (response) => response,
      (err) => {
        if (err.response) {
          console.log(err.response.data.message);
        } else if (err.request) {
          console.log(err.request);
        } else {
          console.log(err);
        }
      }
    );
  },

  // *****************************************************/
  //                    GET ALL
  // *****************************************************/
  // async getAllInvoices(accessToken) {
  //   let res = await axios.get('/service_invoices/invoices/all', {
  //     headers: { Authorization: `Bearer ${accessToken}` }
  //   });
  //   return res.data;
  // },

  // *****************************************************/
  //                    GET BY UUID
  // *****************************************************/

  async getInvoice(uuid, accessToken) {
    let res = await axios({
      method: 'GET',
      url: '/service_invoices/invoices/' + uuid,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  // *****************************************************/
  //                    UPDATE
  // *****************************************************/

  async updateInvoice(uuid, data, accessToken) {
    let res = await axios({
      method: 'PUT',
      url: '/service_invoices/invoices/' + uuid,
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  // *****************************************************/
  //                    CREATE
  // *****************************************************/

  async createInvoice(data, accessToken) {
    // add new uuid
    if (!data.uuid) {
      data.uuid = uuid.v4();
    }

    let res = await axios({
      method: 'POST',
      url: '/service_invoices/invoices/create',
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  async bulkCreateInvoiceItems(data, accessToken) {
    // add new uuid
    if (!data.uuid) {
      data.uuid = uuid.v4();
    }

    let res = await axios({
      method: 'POST',
      url: '/service_invoices/invoice_items/bulk_create',
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return !!res;
  },

  async createInvoicePayment(data, accessToken) {
    // add new uuid
    if (!data.uuid) {
      data.uuid = uuid.v4();
    }

    let res = await axios({
      method: 'POST',
      url: '/service_invoices/invoice_payments/create',
      data: data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  // *****************************************************/
  //                    GET BY PARAMS
  // *****************************************************/
  async getInvoicesByTenant(tenant_uuid, params, accessToken) {
    let res = await axios({
      method: 'GET',
      url: `/service_invoices/invoices/bytenant/${tenant_uuid}`,
      params: params || {},
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  async getInvoicesByIDArray(tenant_uuid, params, accessToken) {
    let res = await axios({
      method: 'GET',
      url: `/service_invoices/invoices/bytenant_and_uuid_array/${tenant_uuid}`,
      params: params,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  async getInvoiceItems(params, accessToken) {
    let res = await axios({
      method: 'GET',
      url: '/service_invoices/invoice_items/all',
      params: params || {},
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  async getInvoicePayments(params, accessToken) {
    let res = await axios({
      method: 'GET',
      url: '/service_invoices/invoice_payments/all',
      params: params || {},
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },

  // *****************************************************/
  //                    EXPORT
  // *****************************************************/

  // expects 'uuid' in params/query
  async exportSnapshot(params, accessToken) {
    let res = await axios({
      method: 'POST',
      url: '/service_invoices/invoices/export_snapshot',
      params: params,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return res.data;
  },
};
